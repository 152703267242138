/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";


@import "/node_modules/quill/dist/quill.core.css";
@import "/node_modules/quill/dist/quill.snow.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";




/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

//@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import "../node_modules/angular-calendar/css/angular-calendar.css";


.hidden {
    display: none;
}

textarea {
    resize: none;
}

.card,
.p-datatable-header,
.p-toolbar {
    padding: 6px !important;
}

.p-card-header {
    padding: 1rem;
}


.p-dialog-header {
    padding: 1rem;
    min-width: 200px;
}

.p-dialog-content {
    padding: 1rem;
    min-width: 200px;
}

.p-confirm-dialog {
    min-height: 250px !important;
}

tr {
    height: 50px !important;
}

td,
th {
    padding: 6px !important;
}

.p-menu-list {
    padding: 0 0.5rem 0 0.5rem;
}


.p-contextmenu ul {
    padding: 0 !important;
    margin-bottom: 0 !important;
}

::placeholder {
    opacity: 40%;
}


.p-inputtext {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}